import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

import "../styles/main.scss";

var getExcerptFromContent = function (content) {
  content = content.trim();
  var contentStartsWithParagraph = content.trim().substring(0,3) === "<p>";

  if (contentStartsWithParagraph === false) {
    // NOT GOOD! return something so that we see the chaos that comes with not starting a post with a paragraph! :P
    return content;
  }

  var firstParagraphCloseIndex = content.indexOf("</p>");

  var excerpt = content.substring(0, firstParagraphCloseIndex) + "...</p>";

  return excerpt;
};

export default ({ data }) => {
  return (
    <Layout>
      {data.allWordpressPost.edges.map(({ node }) => (
        <div className="item-color" style={{ padding: "20px", margin: "10px", borderStyle: "solid", borderWidth: "0px", boxShadow: "4px 4px 4px -2px #C7C7C7" }}>
          <Link to={"/" + node.slug + "/"}>
            <h2 dangerouslySetInnerHTML={{ __html: node.title }} style={{ margin: "0px 0px 10px 0px" }} />
          </Link>
          <h5 dangerouslySetInnerHTML={{ __html: node.date }} style={{ margin: "0px 0px 7px 0px" }} />
          <div dangerouslySetInnerHTML={{ __html: getExcerptFromContent(node.content) }} />
          <Link to={"/" + node.slug + "/"}>
            <span>Read more</span>
            <svg role="img" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" height="30px" style={{ verticalAlign:"middle", fill: "rgb(0, 119, 192)"}}>
              <path d="M434.39,264.93c0.24-0.35,0.45-0.72,0.66-1.09c0.05-0.09,0.1-0.17,0.15-0.26c0.19-0.35,0.35-0.7,0.51-1.06    c0.05-0.12,0.12-0.24,0.17-0.36c0.14-0.34,0.26-0.69,0.38-1.03c0.05-0.15,0.11-0.3,0.16-0.45c0.11-0.35,0.19-0.7,0.27-1.06    c0.04-0.16,0.08-0.31,0.11-0.47c0.08-0.42,0.14-0.84,0.19-1.26c0.01-0.1,0.03-0.2,0.04-0.3c0.11-1.07,0.11-2.14,0-3.21    c-0.02-0.17-0.05-0.34-0.07-0.52c-0.05-0.34-0.09-0.69-0.16-1.02c-0.05-0.24-0.12-0.48-0.17-0.72c-0.07-0.27-0.12-0.53-0.2-0.8    c-0.08-0.25-0.17-0.5-0.26-0.74c-0.09-0.24-0.17-0.49-0.27-0.73c-0.1-0.25-0.22-0.49-0.34-0.73c-0.11-0.23-0.21-0.46-0.33-0.69    c-0.13-0.25-0.29-0.49-0.43-0.74c-0.12-0.2-0.23-0.4-0.37-0.6c-0.2-0.3-0.42-0.59-0.65-0.88c-0.1-0.12-0.18-0.25-0.28-0.37    c-0.35-0.43-0.72-0.84-1.12-1.23L317.63,129.88c-6.25-6.25-16.38-6.25-22.63,0c-6.25,6.25-6.25,16.38,0,22.63l87.49,87.49H90.88    c-8.84,0-16,7.16-16,16s7.16,16,16,16H382.5L295,359.5c-6.25,6.25-6.25,16.38,0,22.63c3.12,3.12,7.22,4.69,11.31,4.69    s8.19-1.56,11.31-4.69l114.81-114.81c0.7-0.7,1.32-1.46,1.86-2.25C434.33,265.02,434.36,264.97,434.39,264.93z"/>
            </svg>
          </Link>
        </div>
      ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          date(formatString: "YYYY-MM-DD")
          excerpt
          slug
          content
        }
      }
    }
  }
`
